import { useState } from "react";
import PATHS from "config/paths";

export function usePostSetting(token, setting) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postRequest = async (setting) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${PATHS.apiSettings}/${setting.key}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(setting),
      });
      if (!response.ok) {
        const message = await response.text();
        throw new Error(message);
      }
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };
  return { postRequest, error, isLoading };
};
