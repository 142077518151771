import React from "react";
import lodash from "lodash";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostVictimMerge(token, requestBody, id) {
  const [response, setResponse] = React.useState();
  const [error, setError] = React.useState();

  /**
   * Make Request
   */
  React.useEffect(() => {
    if (token && requestBody && id) {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };

      const path = `${PATHS.apiVictims}/${id}/merge`;

      async function postRequest() {
        const request = await fetch(path, options);
        return request.json();
      }

      postRequest()
        .then((data) => {
          if (data) setResponse(data);
          else setError("unknown-error");
        })
        .catch((_error) => setError(_error.message));
    }
  }, [token, requestBody, id, setResponse]);

  return { response,  error };
}
