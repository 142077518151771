const PATHS = {
  root: "/",
  calendar: "/calendar",
  manageUsers: "/manage-users",

  // Vicitims
  victimProfile: "/victims",
  victimsBrowse: "/victims/browse",
  victimsNew: "/victims/new",
  victimsSearch: "/victims/search",
  victimsMerge: "/victims/Merge",

  // Survivors
  survivorsProfile: "/survivors",
  survivorssBrowse: "/survivors/browse",
  survivorssNew: "/survivors/new",
  survivorssSearch: "/survivors/search",

  // Tasks
  tasksList: "/tasks/browse",
  tasksView: "/tasks/view",

  // Notifications
  notificationsView: "/notifications/browse",

  // Caase Submission 
  caseSubmissionView: "/submissions/view",
  caseSubmissions: "/submissions/browse",

  // API
  apiSettings: `${process.env.REACT_APP_API_PATH}/api/v1/settings`,
  apiAnniversaries: `${process.env.REACT_APP_API_PATH}/api/v1/victims/upcomming_anniversaries`,
  apiCalendarEvents: `${process.env.REACT_APP_API_PATH}/api/v1/events/calendar_events`,
  apiCase: `${process.env.REACT_APP_API_PATH}/api/v1/cases`,
  apiDocumnents: `${process.env.REACT_APP_API_PATH}/api/v1/documents`,
  apiCaseSubmissions: `${process.env.REACT_APP_API_PATH}/api/v1/case_submissions`,
  apiContacts: `${process.env.REACT_APP_API_PATH}/api/v1/contacts`,
  apiEvents: `${process.env.REACT_APP_API_PATH}/api/v1/events`,
  apiEventsUpcoming: `${process.env.REACT_APP_API_PATH}/api/v1/events/upcoming_events`,
  apiNewVictims: `${process.env.REACT_APP_API_PATH}/api/v1/victims/new_victims`,
  apiNotifications:  `${process.env.REACT_APP_API_PATH}/api/v1/notifications`,
  apiNotificationsMarkRead:  `${process.env.REACT_APP_API_PATH}/api/v1/notifications/mark_read`,
  apiNotificationsMarkAllRead:  `${process.env.REACT_APP_API_PATH}/api/v1/notifications/mark_all_read`,
  apiRegistration: `${process.env.REACT_APP_API_PATH}/api/v1/registrations`,
  apiReportTouchpoints: `${process.env.REACT_APP_API_PATH}/api/v1/reports/touchpoints`,
  apiReportCases: `${process.env.REACT_APP_API_PATH}/api/v1/reports/cases`,
  apiSocials: `${process.env.REACT_APP_API_PATH}/api/v1/socials`,
  apiSurvivors: `${process.env.REACT_APP_API_PATH}/api/v1/survivors`,
  apiTasks: `${process.env.REACT_APP_API_PATH}/api/v1/tasks`,
  apiTouchpoints: `${process.env.REACT_APP_API_PATH}/api/v1/touchpoints`,
  apiVictims: `${process.env.REACT_APP_API_PATH}/api/v1/victims`,
  apiVictimsRemembrances: `${process.env.REACT_APP_API_PATH}/api/v1/victims/todays_rememberances`,
  apiDocuments: `${process.env.REACT_APP_API_PATH}/api/v1/documents`,
  apiActiveUsersList: `${process.env.REACT_APP_API_PATH}/api/v1/users/list`,
};

export default PATHS;
