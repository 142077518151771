import { useState, useEffect } from "react";
import PATHS from "config/paths";

export function useGetSettings (token, refresh=false) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if ((token) || (token && refresh)) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(PATHS.SETTINGS).then(res => res.json());
          setData(response.data);
        } catch (error) {
          setError(error);
        } finally {
          setIsLoading(false);
          setIsLoading(false);
        }
      };
      fetchData();}
  }, [token, refresh]);

  return { data, error, isLoading };
};
