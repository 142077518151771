import React from "react";
import AdminLayout from "layouts/Admin.js";
import PATHS from "config/paths";
import { useTranslation } from "react-i18next";
import { lighten, makeStyles } from '@material-ui/core/styles';
import lodash from "lodash";

import { formatDate, readableEnum } from "utilities";
import { useGetVictims, useGetVictimsCsv } from "hooks";
import { setVictimSearchCache, getVictimSearchCache } from "utilities/victim-search-cache";
import { useHistory } from "react-router-dom";
import { yes_no, cause_of_death, gender, race, special_categories, state, case_statuses } from "config/enums";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { InputDate, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

// Material UI
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl';


// Icons
import Add from "@material-ui/icons/Add";

// Styles
import style from "assets/jss/pages/PageStyles.js";
import { set } from "js-cookie";
const useStyles = makeStyles(style);
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));


function Page () {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const classes = useStyles();
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const boxSettings = {};
  const [view, setView] = React.useState("search");
  const [page, setPage] = React.useState(1);
  const [error, setError] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [searchParams, setSearchParams] = React.useState();
  const [shouldDownload, setShouldDownload] = React.useState(false);
  const { isCompleted, downloadError, isDownloading } = useGetVictimsCsv(token, searchParams, shouldDownload);

  const [orderDir, setOrderDir] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [sort, setSort] = React.useState();

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSort(`sorted_by=${property}_${orderDir}`);
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { victims, victimsError, victimsPageCount, isLoading } = useGetVictims(
    token,
    page,
    searchParams,
    true,
    true,
    sort);

  const headCells = [
    { id: 'pcc_case_number', numeric: false, disablePadding: false, label: t('case.number') },
    { id: 'pcc_case_status', numeric: false, disablePadding: false, label: t('case.status') },
    { id: 'first_name_', numeric: false, disablePadding: true, label: t('firstname') },
    { id: 'last_name_', numeric: false, disablePadding: false, label: t('lastname') },
    { id: 'age', numeric: false, disablePadding: false, label: t('age') },
    { id: 'gender_', numeric: false, disablePadding: false, label: t('gender') },
    { id: 'race_', numeric: false, disablePadding: false, label: t('race') },
    { id: 'cause_of_death_', numeric: false, disablePadding: false, label: t('cause') },
    { id: 'incident_city', numeric: false, disablePadding: false, label: t('incident.city') },
    { id: 'incident_state', numeric: false, disablePadding: false, label: t('incident.state') },
    { id: 'incident_date', numeric: false, disablePadding: false, label: 'Incident Date' },
    { id: 'date_of_death', numeric: false, disablePadding: false, label: 'Date of Death' },
  ];

  const MONTHS = [
    ["January", 1],
    ["February", 2],
    ["March", 3],
    ["April", 4],
    ["May", 5],
    ["June", 6],
    ["July", 7],
    ["August", 8],
    ["September", 9],
    ["October", 10],
    ["November", 11],
    ["December", 12],
  ];

  const defaultSearch = {
    by_first_name: "",
    by_last_name: "",
    by_any_name: "",
    by_cause_of_death: "",
    by_gender: "",
    by_race: "",
    by_state: "",
    by_city: "",
    by_law_enforcement: "",
    by_postal_code: "",
    by_special_category: "",
    by_dob_start: "",
    by_dob_end: "",
    months_events: "",
    by_death_start: "",
    by_death_end: "",
    by_city_of_death: "",
    by_state_of_death: "",
    by_created_before: "",
    by_created_after: "",
    by_status: "",
    by_case_number: "",
  };

  const [searchCache, setSearchCache] = React.useState(defaultSearch);
  React.useEffect(() => {
    if (view == "search") {
      let cache = getVictimSearchCache();
      cache = cache.data[State.user.value.id]?.val
      if (!lodash.isEmpty(cache) && !lodash.isEqual(cache, searchCache)) {
        setSearchCache({ ...defaultSearch, ...cache });
      }
    }
  }, [view]);


  const formik = useFormik({
    initialValues: { ...searchCache },
    enableReinitialize: true,
    onSubmit: (values) => {
      setSearchParams(undefined);
      setVictimSearchCache(State.user.value.id, { ...values });
      const keys = Object.keys(values);
      let count = 0;
      const params = keys
        .map((item) => {
          const seperator = count === 0 ? "" : "&";
          if (values[item]) {
            count++;
            return `${seperator}${item}=${values[item]}`;
          }
          return "";
        })
        .join("")
        .replaceAll(" ", "%20");

      if (params.length === 0) {
        setError("no-empty-searches");
        setDisable(false);
      } else {
        setError(undefined);
        setView("loading");
        setSearchParams(params);
      }
    },
  });

  const handleDownload = () => {
    setShouldDownload(true);
  };

  const handleReset = () => {
    setDisable(false);
    setSearchParams(undefined);
    setPage(1);
    formik.handleReset();
    setView("search");
  };

  const handleSubmit = () => {
    if (disable) return;
    setDisable(true);
    formik.handleSubmit();
  };

  // Show Error message
  React.useEffect(() => {
    if (victimsError) {
      setView("search");
      setDisable(false);
    }
  }, [victimsError]);

  // Show Error message
  React.useEffect(() => {
    if (victims && victims.length > 0) setView("results");
  }, [victims]);

  React.useEffect(() => {
    if (isCompleted) setShouldDownload(false);
  }, [isCompleted]);

  const [open, setOpen] = React.useState();
  React.useEffect(() => {
    if (open) {
      if (open.a == "open") {
        history.push(`${PATHS.victimProfile}/${open.id}`);
      }
      if (open.a == "tab") {
        window.open(`${PATHS.victimProfile}/${open.id}`);
      }
      // todo get chrome to open new window
      if (open.a == "window") {
        const target = `_win${open.id}`;
        window.open(`${PATHS.victimProfile}/${open.id}`, target);
      }

    }
  }, [open]);

  return (
    <div id="page-vic-new">
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          Search Victims
        </Typography>
      </Box>

      {view === "loading" && (
        <Box textAlign="center" py={5}>
          <p>{t("wait")}</p>
          <CircularProgress />
        </Box>
      )}

      {view === "search" && (
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Add />
            </CardIcon>
          </CardHeader>
          <CardBody>
            {victimsError && (
              <Box textAlign="center" py={5}>
                <p>{t("noresults")}</p>
              </Box>
            )}

            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <input type="submit" style={{ display: "none" }} />
              <h3>Victim Details</h3>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputText
                      label={t("firstname")}
                      name="by_first_name"
                      value={formik.values.by_first_name}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputText
                      label={t("lastname")}
                      name="by_last_name"
                      value={formik.values.by_last_name}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>                   
                    <label>
                      Status
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_status,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_status",
                          id: "by_status",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {case_statuses.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)} 
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>{" "}
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputText
                      label={"Any Name"}
                      name="by_any_name"
                      value={formik.values.by_any_name}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputText
                      label={"PCC Case Number"}
                      name="by_case_number"
                      id="by_case_number"
                      value={formik.values.by_case_number}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      Submitted by Law Enforcement
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_law_enforcement,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_law_enforcement",
                          id: "by_law_enforcement",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {yes_no.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>{" "}
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      <InputText
                        label={t("city")}
                        name="by_city"
                        value={formik.values.by_city}
                        onChange={formik.handleChange}
                      />
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      {t("state")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_state,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_state",
                          id: "by_state",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {state.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <label>

                      <InputText
                        label={t("zip")}
                        name="by_postal_code"
                        value={formik.values.by_postal_code}
                        onChange={formik.handleChange}
                      />
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputDate
                      value={formik.values.by_dob_start}
                      label={t("dobstart")}
                      name="by_dob_start"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputDate
                      value={formik.values.by_dob_end}
                      label={t("dobend")}
                      name="by_dob_end"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      Anniversary Month
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_created_month,
                          onChange: (e) => formik.handleChange(e),
                          name: "months_events",
                          id: "months_events",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {MONTHS.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item[1]}>
                              {item[0]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputDate
                      value={formik.values.by_death_start}
                      label={t("dodstart")}
                      name="by_death_start"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputDate
                      value={formik.values.by_death_end}
                      label={t("dodend")}
                      name="by_death_end"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>
                  
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      {t("causeofdeath")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_cause_of_death,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_cause_of_death",
                          id: "by_cause_of_death",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {cause_of_death.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      {t("gender")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_gender,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_gender",
                          id: "by_gender",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {gender.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      {t("specialcat")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_special_category,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_special_category",
                          id: "by_special_category",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {special_categories.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      <InputLabel htmlFor="by_race" className={classes.selectLabel}>
                        {t("race")}
                      </InputLabel>
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_race,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_race",
                          id: "by_race",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {race.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputDate
                      value={formik.values.by_created_after}
                      label={"Created After"}
                      name="by_created_after"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputDate
                      value={formik.values.by_created_before}
                      label={"Created Before"}
                      name="by_created_before"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              {error === "no-empty-searches" && (
                <Box textAlign="center" pb={5}>
                  {t("error.search.noempty")}
                </Box>
              )}
              <Box {...boxSettings}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <Button color={disable ? undefined : "info"} onClick={handleSubmit}>
                      {t("search")}
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Box textAlign="right">
                      <Button onClick={() => setSearchCache(defaultSearch)}>Reset</Button>
                    </Box>
                  </GridItem>
                </GridContainer>
              </Box>
            </form>
          </CardBody>
        </Card>
      )}

      {view === "results" && (
        <React.Fragment>
          {victims && victims?.length > 0 && !isLoading && (
            <React.Fragment>
              {/* <button onClick={() => setView("search")}>Back to search</button> */}
              <Box textAlign="center" paddingBottom={5}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <Button size="sm" onClick={handleReset}>
                    {t("backtosearch")}
                  </Button>
                  <Button size="sm" onClick={handleDownload}>
                    {"Download CSV"}
                  </Button>
                </ButtonGroup>
              </Box>

              <TableContainer component={Paper}>
                <Table aria-label="simple table" className="hover">
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? 'right' : 'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection={orderBy === headCell.id ? orderDir : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? orderDir : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>
                                {orderDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {victims.map((victim, index) => {
                      const date = victim.date_of_death ? new Date(victim.date_of_death) : null;
                      const displaydate = date ? `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}` : null;

                      return (
                        <TableRow key={index} >
                          <TableCell>{victim.case.case_number}</TableCell>
                          <TableCell>{readableEnum(victim.case.status)}</TableCell>
                          <TableCell>{victim.first_name}</TableCell>
                          <TableCell>{victim.last_name}</TableCell>
                          <TableCell>{victim.age}</TableCell>
                          <TableCell>{readableEnum(victim.gender)}</TableCell>
                          <TableCell>{readableEnum(victim.race)}</TableCell>
                          <TableCell>{readableEnum(victim.cause_of_death)}</TableCell>
                          <TableCell>{victim.case.incident?.city || ""}</TableCell>
                          <TableCell>{victim.case.incident?.state || ""}</TableCell>
                          <TableCell>{formatDate(victim.case.incident?.date_of_incident || "")}</TableCell>
                          <TableCell>{formatDate(victim.date_of_death)}</TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Open</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                label="Age"
                                onChange={(e) => { setOpen(e.target.value) }}
                              >
                                <MenuItem value={{ a: "open", id: victim.id }}>Open</MenuItem>
                                <MenuItem value={{ a: "tab", id: victim.id }}>Open in New Tab</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}

          {victims && victimsPageCount > 0 && (
            <Box textAlign="center" mt={5}>
              <Pagination disabled={isLoading} page={page} count={victimsPageCount} onChange={(e, p) => setPage(p)} />
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default function PageVictimsSearch () {
  return <AdminLayout component={<Page />} />;
}
