import React from "react";
import PATHS from "config/paths";
import LoadingScreen from "pages/LoadingScreen";

export function useGetVictimSimpleList(token, refresh = false) {
  const [simpleList, setSimpleList] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleResponse = (data) => {
    if (data) {
      setSimpleList(data);
    } else {
      setError(new Error("data-data"));
    }
  };

  React.useEffect(() => {
    if ((token && !simpleList) || (token && refresh)){
      setLoading(true);
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(`${PATHS.apiVictims}/simple_list/`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => console.warn("error", error))
        .finally(() => setLoading(false));
    }
  }, [token, simpleList, refresh]);

  return { simpleListResponse: simpleList, simpleListLoading: loading, simpleListError: error };
}
