import React from "react";
import { StateContext } from "state.js";
import styled from "styled-components";

// Material UI
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from '@material-ui/core/FormHelperText';
import { useGetActiveUsersList } from "hooks";




const StyledInputDate = styled.div`
  .input-box {
    width: 33.33%;
  }
`;

export function ActiveUserDropdown({ label, onChange, value, helperText, disableChoose=true, multiple=false }) {
  const State = React.useContext(StateContext);
  const [initialValue, setInitialValue] = React.useState(value);
  const [selected, setSelected] = React.useState("");
  const [token] = React.useState(State?.user?.value?.token);
  const [activeUsers, setActiveUsers] = React.useState([]);
  const [menuOptions, setMenuOptions] = React.useState();
  const useGetActiveUsersResult = useGetActiveUsersList(token);

  React.useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected]);

  React.useEffect(() => {
    if(useGetActiveUsersResult && useGetActiveUsersResult.activeUsers) {
      setActiveUsers(useGetActiveUsersResult.activeUsers);
    }
  }, [useGetActiveUsersResult])
    
  React.useEffect( () => {
    if (activeUsers && activeUsers.length > 0) {
      const data = (disableChoose) ? [<MenuItem disabled key={0} value={null}>Choose one</MenuItem>] : [<MenuItem key={0} value={-1}>All</MenuItem>];
      activeUsers.map(item => {
        data.push(<MenuItem key={item.id} value={item.id}>{`${item.first_name} ${item.last_name}`}</MenuItem>)
      });
      setMenuOptions(data);
      setSelected(initialValue);
    }
  }, [activeUsers]);

  

  return (
    <>
      
          <InputLabel id="active_users_label">{label}</InputLabel>
          <Select
            inputProps={{
              labelId: "active_users_label",
              id: "active_users",
              value: selected,
              defaultValue: "",
              name: "active_users",
              onChange: (e) => setSelected(e?.target?.value),
            }}
            children={menuOptions} 
            multiple={multiple}       
          >
            
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
   
    </>
  );
}
