import React from 'react';
import AdminLayout from "layouts/Admin.js";
import { StateContext } from "state.js";

import { useParams } from 'react-router-dom';
import {  useGetVictim, usePostVictimMerge } from 'hooks';
import VictimDiff from 'components/VictimDiff';
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import { useHistory } from 'react-router-dom';
import Button from "components-material-ui/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from '@material-ui/core/Slide';

function Page() {
  const State = React.useContext(StateContext);
  const history = useHistory();
  const { sourceId, targetId } = useParams();
  const [token] = React.useState(State?.user?.value?.token);
  const  sourceResponse = useGetVictim(token, sourceId);
  const targetResponse = useGetVictim(token, targetId);
  const [mergeResult, setMergeResult] = React.useState();

  const [source, setSource] = React.useState();
  const [target, setTarget] = React.useState();
  const [sourceError, setSourceError] = React.useState();
  const [targetError, setTargetError] = React.useState();


  const [mergedSocials, setMergedSocials] = React.useState();
  const [mergedSurvivors, setMergedSurvivors] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const checkValueExists = (arr, key, value) => {
    if(!arr.length || !value || !key) return false;
    return arr.some(obj => obj && obj[key] === value);
  }

  const findById = (arr, id) => {
    return arr.find(obj => obj.id === parseInt(id));
  }
  const [requestBody, setRequestBody] = React.useState();
  const {response, error} = usePostVictimMerge(token, requestBody, sourceId);


  React.useEffect(() => {
    if(!response) return;
    console.log("response", response);
    setOpen(false);
    if(response.is_error) {
      setOpenError(true);
      return;
    }
    setOpenSuccess(true);
  }, [response]);

  React.useEffect(() => {
    if(!error) return;
    setOpen(false);
    console.log("error", error);
    setOpenError(true);
  }, [error]);

  React.useEffect(() => {
    if(!source || !target) return;
    if(!source.case?.socials || !target.case?.socials ) return;
    console.log("source", source.case.socials);
    console.log("target", target.case.socials);
    const merged = [...source.case.socials, ...target.case.socials];
    console.log("merged", merged);
    setMergedSocials(merged);
  }, [source, target]);

  React.useEffect(() => {
    if(!source || !target) return;
    if(!source.case?.survivors || !target.case?.survivors ) return;
    console.log("source", source.case.survivors);
    console.log("target", target.case.survivors);
    const merged = [...source.case.survivors, ...target.case.survivors];
    console.log("merged", merged);
    setMergedSurvivors(merged);
  }, [source, target]);

  React.useEffect(() => {
    if(source) return;
    console.log("sourceResponse", sourceResponse);

    if (sourceResponse && sourceResponse.victimGet) {
      setSource(sourceResponse.victimGet);
      const clone = JSON.parse(JSON.stringify(sourceResponse.victimGet));
      setMergeResult(clone);
    }
  }, [sourceResponse]);

  React.useEffect(() => {
    if(target) return;
    console.log("targetResponse", targetResponse);

    if(targetResponse && targetResponse.victimGet){
      setTarget(targetResponse.victimGet);
    }
  }, [targetResponse]);

  const handleSocialCheckboxChange = (event, id) => {
    const { checked } = event.target;
    console.log('handleCheckboxChange',id);
    const clone = JSON.parse(JSON.stringify(mergeResult));
    if (checked) {
      
      const social = findById(mergedSocials, id);
      clone.case.socials = [...clone.case.socials, social];
      console.log('checked', clone);
      setMergeResult(clone);
    } else {
      clone.case.socials = clone.case.socials.filter(s => s.id !== id);
      console.log('Unchecked', clone);
      setMergeResult(clone);     
    }
  };

  const handleChange = (attr, value) => {
    console.log('handleChange',attr, value);
    const clone = JSON.parse(JSON.stringify(mergeResult));
    clone[attr] = value;
    setMergeResult(clone);
  };

  const handleSurvivorCheckboxChange = (event, id) => {
    const { checked } = event.target;
    console.log('handleSurvivorCheckboxChange',id);
    const clone = JSON.parse(JSON.stringify(mergeResult));
    if (checked) {
      
      const survivor = findById(mergedSurvivors, id);
      clone.case.survivors = [...clone.case.survivors, survivor];
      console.log('checked', clone);
      setMergeResult(clone);
    } else {
      clone.case.survivors = clone.case.survivors.filter(s => s.id !== id);
      console.log('Unchecked', clone);
      setMergeResult(clone);     
    }
  };

  const handleSubmit = () => {
    console.log("mergeResult", mergeResult);
    if(!mergeResult) return;
    const body = {...mergeResult, merge_source_id: targetId};
    setRequestBody(body);
  }

  return (
    <div>
      <h1>Merge Victims</h1>
      <h2>Comparing Victim {sourceId} and Victim {targetId}</h2>
      <p>
        Use the checkbox to pick which attribute to merge. The Merged column is the result of all choices.
        Once all attributes are selected, click Merge. If you wish to add new data, you will need to do so after the merge on the merged Victim Profile page.
      </p>
      <p>Any documents assigned to the victims will be merged. This may cause duplication if so use the profile edit screens to resolve.</p>
      <p className="danger">Be aware that the merge is irreversible. Once the merge is complete, the victims will be deleted.</p>
      {sourceError && <div>Error: {sourceError.message}</div>}
      {targetError && <div>Error: {targetError.message}</div>}
      {!source && !target && <div>Loading...</div>}
      <h3>Victim Attributes</h3>
      {source && target && <VictimDiff source={source} target={target} handleChange={handleChange} />}
      <h3>Case Attributes</h3>
      {source && target && <VictimDiff source={source.case} target={target.case} />}
      <h3>Incident Attributes</h3>
      {source && target && <VictimDiff source={source.case.incident} target={target.case.incident} />}
      <h3>Social Media Attributes</h3>
      {source && target && mergedSocials && mergedSocials.length > 0 && (
        mergedSocials.map((social, index) => (
          <Card  key={index}>
              <CardBody>
                <h3>{social.post_type} <input
                type="checkbox"
                checked={checkValueExists(mergeResult.case.socials, "id", social.id)}
                onChange={(event) => handleSocialCheckboxChange(event, social.id)}
              /></h3>
                <h4>{social.date_of_post}</h4>
                <a href={social.link} target='_blank'>{social.link}</a>
                <div>
                  {social.desciption}
                </div>
              </CardBody>
          </Card>
        ))
      )}
      <h3>Survivors</h3>
      {source && target && mergedSurvivors && mergedSurvivors.length > 0 && (
        mergedSurvivors.map((survivor, index) => (
          <Card  key={index}>
            <CardBody>
              <h3>{survivor.first_name} {survivor.last_name} ({survivor.id}) 
                <input
                type="checkbox"
                checked={checkValueExists(mergeResult.case.survivors, "id", survivor.id)}
                onChange={(event) => handleSurvivorCheckboxChange(event, survivor.id)}
              />
              </h3>
              <address>
              {survivor.address1} {survivor.address2} {survivor.city}, {survivor.state}
              </address>
              <p>{survivor.phone}<br/>
              {survivor.email}</p>
            </CardBody>
          </Card>
        ))
      )}

    <Button color="primary" onClick={() => setOpen(true)}>
      Merge Victims
    </Button>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Merge Victims"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to merge the victims? This action is irreversible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Merge
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={openSuccess}
      TransitionComponent={Transition}
      
      onClose={() => setOpenSuccess(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Merge Success"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          The victims have been merged.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.push(`/victims/${sourceId}`)} color="primary">
          OK
        </Button>        
      </DialogActions>
    </Dialog>
    <Dialog
      open={openError}
      TransitionComponent={Transition}
      
      onClose={() => setOpenError(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Merge Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Error: {error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenError(false)} color="primary">
          OK
        </Button>        
      </DialogActions>
    </Dialog>
      
    </div>
  );
};

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '10px',
  },
  column: {
    backgroundColor: '#f0f0f0',
    padding: '20px',
  },
  highlight: {
    backgroundColor: '#ffff00', // yellow highlight
  },
};

export default function PageVictimMerge() {
  return <AdminLayout component={<Page />} />;
}
