import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from "state.js";
import AdminLayout from "layouts/Admin.js";


import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, FormControl, InputLabel, Select, MenuItem, CardActions } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PATHS from 'config/paths';
import { useGetSettings, usePostSetting } from 'hooks';
import { ActiveUserDropdown } from "components";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardBody from "components-material-ui/Card/CardBody.js";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function PageSettings () {
  const classes = useStyles();
  const history = useHistory();
  const State = useContext(StateContext);
  const [token] = useState(State?.user?.value?.token);
  const [refresh, setRefresh] = useState(false);
  const refWrapper = React.useRef();


  const [settings, setSettings] = useState({
    "CaseSubmissionNotifications": [],
  });
  const { data: settingsData, error, isLoading } = useGetSettings(token, refresh);
  const { postRequest: postSetting } = usePostSetting(token);

  const [caseSubmissionNotificationUsers, setCaseSubmissionNotificationUsers] = useState([]);

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData);
    }
  }, [settingsData]);

  const handleUserNotificationSubmit = (event) => {
    event.preventDefault();
    const setting = {
      "key": "CaseSubmissionNotifications",
      "value": caseSubmissionNotificationUsers
    }
    postSetting(setting);
    //story.push(PATHS.DASHBOARD);
  };

  const handleChange = (val) => {
    setCaseSubmissionNotificationUsers(val);
  };

  return (

    <div ref={refWrapper}>
      <h1>App Settings</h1>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/" >
          Dashboard
        </Link>
        <Typography color="textPrimary">Settings</Typography>
      </Breadcrumbs>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card>
            <CardHeader>
              <div>Case Submission Notifications</div>
              <p>Select users to receive notifications when a case is submitted.</p>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth>
                <ActiveUserDropdown
                  label="Assign To"
                  onChange={(val) => { handleChange(val) }}
                  value={caseSubmissionNotificationUsers}
                  multiple
                  helperText="Select users to receive notification"
                />
              </FormControl>
            </CardBody>
            <CardActions><FormControl className={classes.formControl}>
              <Button type="submit" variant="contained" color="primary" onClick={handleUserNotificationSubmit}>
                Update
              </Button>
            </FormControl></CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default function PageSettingsView () {
  return <AdminLayout component={<PageSettings />} />;
}
