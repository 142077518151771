import { css, createGlobalStyle } from "styled-components";

export const color = {
  black: "#141414",
  gray: "#EFEFEF",
  white: "#FFFFFF",
  blueDark: "#1B365D",
  green: "#3D513B",
  blueLight: "#1EC7E2",
  red: "#ff5454",
};

const label = css`
  color: #888888 !important;
  font-family: "Lato", "Helvetica", "Arial", sans-serif !important;
  font-size: 14px !important;
  opacity: 1 !important;
  text-transform: none !important;
`;

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    float: left;
    margin: 0 0 2em;
  }
  table thead {
    border-bottom: 3px solid #008cba;
  }
  table thead th {
    padding: 0.35em 0 0.35em;
    font-weight: 400;
    text-align: left;
    font-family: "Helvetica", "Helvetica Neue", "Arial", sans-serif;
    font-size: 1.25em;
  }
  table tbody {
    border-bottom: 3px solid #008cba;
  }
  table tbody tr {
    border-bottom: 2px solid #ddd;
  }
  table tbody tr td {
    padding: 0.75em 0;
  }
  table tbody tr td a {
    color: #008cba;
    text-decoration: none;
    display: inline-block;
    margin: 0 0.5em 0 0;
  }
  table tbody tr td a:hover, table tbody tr td a:active, table tbody tr td a:focus {
    color: #006687;
    border: none;
  }
  table tfoot td {
    padding: 0.35em 0 0.35em;
    text-align: left;
    font-family: "Helvetica", "Helvetica Neue", "Arial", sans-serif;
    font-size: 1.25em;
  }

  @media screen and (min-width: 1000px) {
    table {
      width: 100%;
    }
    table thead {
      border-bottom: 3px solid #008cba;
    }
    table tbody tr {
      border-bottom: 1px solid #ddd;
    }
  }
  .rwd-table {
    display: block;
  }
  .rwd-table tbody {
    border: none;
  }
  .rwd-table tbody, .rwd-table th, .rwd-table td, .rwd-table tr, .rwd-table tfoot {
    display: block;
  }
  .rwd-table thead {
    display: none;
  }
  .rwd-table tr {
    float: left;
    width: 100%;
    margin: 0 0 1em;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 5px 0px #ddd;
    border-radius: 4px;
    border-top: 5px solid #008cba;
  }
  .rwd-table tr td {
    padding: 0.5em 0.5em 0.5em 50%;
    float: left;
    width: 100%;
  }
  .rwd-table tr td:before {
    width: 100%;
    display: block;
    float: left;
    padding: 0.5em 0.5em 0.5em 0;
    clear: both;
    margin: -0.5em 0 0 -100%;
    font-family: "Helvetica", "Helvetica Neue", "Arial", sans-serif;
    font-size: 1.125em;
  }
  @media screen and (min-width: 600px) {
    .rwd-table {
      display: table;
      border: none;
    }
    .rwd-table tbody {
      border-bottom: 3px solid #008cba;
    }
    .rwd-table th, .rwd-table td {
      display: table-cell;
    }
    .rwd-table tr {
      display: table-row;
      border: none;
      border-bottom: 1px solid #eee;
      float: none;
      margin: 0;
      box-shadow: none;
    }
    .rwd-table tr td {
      padding: 0.75em 0 0.75em 0.25em;
      float: none;
      width: auto;
    }
    .rwd-table tr td:before {
      padding: 0 !important;
    }
    .rwd-table thead {
      display: table-header-group;
    }
    .rwd-table tbody, .rwd-table tfoot {
      display: table-row-group;
    }
    .rwd-table tr:nth-of-type(even) {
      background: none;
    }
  }

  .rwd-table-large {
    display: block;
  }
  .rwd-table-large tbody {
    border: none;
  }
  .rwd-table-large tbody, .rwd-table-large th, .rwd-table-large td, .rwd-table-large tr, .rwd-table-large tfoot {
    display: block;
  }
  .rwd-table-large thead {
    display: none;
  }
  .rwd-table-large tr {
    float: left;
    width: 100%;
    margin: 0 0 1em;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 5px 0px #ddd;
    border-radius: 4px;
    border-top: 5px solid #008cba;
  }
  .rwd-table-large tr td {
    padding: 0.5em 0.5em 0.5em 50%;
    float: left;
    width: 100%;
  }
  .rwd-table-large tr td:before {
    width: 100%;
    display: block;
    float: left;
    padding: 0.5em 0.5em 0.5em 0;
    clear: both;
    margin: -0.5em 0 0 -100%;
    font-family: "Helvetica", "Helvetica Neue", "Arial", sans-serif;
    font-size: 1.125em;
  }
  @media screen and (min-width: 1000px) {
    .rwd-table-large {
      display: table;
      border: none;
    }
    .rwd-table-large tbody {
      border-bottom: 3px solid #008cba;
    }
    .rwd-table-large th, .rwd-table-large td {
      display: table-cell;
    }
    .rwd-table-large tr {
      display: table-row;
      border: none;
      border-bottom: 1px solid #eee;
      float: none;
      margin: 0;
      box-shadow: none;
    }
    .rwd-table-large tr td {
      padding: 0.75em 0 0.75em 0.25em;
      float: none;
      width: auto;
    }
    .rwd-table-large tr td:before {
      padding: 0 !important;
    }
    .rwd-table-large thead {
      display: table-header-group;
    }
    .rwd-table-large tbody, .rwd-table-large tfoot {
      display: table-row-group;
    }
    .rwd-table-large tr:nth-of-type(even) {
      background: none;
    }
  }

  .rwd-table tbody tr td:nth-of-type(1):before {
    content: "Heading Col 1";
  }
  @media screen and (min-width: 600px) {
    .rwd-table tbody tr td:nth-of-type(1):before {
      content: "";
    }
  }
  .rwd-table tbody tr td:nth-of-type(2):before {
    content: "Heading Col 2";
  }
  @media screen and (min-width: 600px) {
    .rwd-table tbody tr td:nth-of-type(2):before {
      content: "";
    }
  }
  .rwd-table tbody tr td:nth-of-type(3):before {
    content: "Heading Col 3";
  }
  @media screen and (min-width: 600px) {
    .rwd-table tbody tr td:nth-of-type(3):before {
      content: "";
    }
  }

  .rwd-table-large tbody tr td:nth-of-type(1):before {
    content: "Heading Col 1";
  }
  @media screen and (min-width: 1000px) {
    .rwd-table-large tbody tr td:nth-of-type(1):before {
      content: "";
    }
  }
  .rwd-table-large tbody tr td:nth-of-type(2):before {
    content: "Heading Col 2";
  }
  @media screen and (min-width: 1000px) {
    .rwd-table-large tbody tr td:nth-of-type(2):before {
      content: "";
    }
  }
  .rwd-table-large tbody tr td:nth-of-type(3):before {
    content: "Heading Col 3";
  }
  @media screen and (min-width: 1000px) {
    .rwd-table-large tbody tr td:nth-of-type(3):before {
      content: "";
    }
  }
  .rwd-table-large tbody tr td:nth-of-type(4):before {
    content: "Heading Col 4";
  }
  @media screen and (min-width: 1000px) {
    .rwd-table-large tbody tr td:nth-of-type(4):before {
      content: "";
    }
  }

  .MuiFormControl-root,
  label, 
  input.form-control,
  input.form-control label {
    ${label}

    * {
      text-transform: none !important;
    }

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder { 
      ${label}
    }

    /* Internet Explorer 10-11 */
    &:-ms-input-placeholder { 
      ${label}
    }

    /* Microsoft Edge */
    ::-ms-input-placeholder { 
      ${label}
    }
  }

  .side-bar-wrapper >ul >li > a {
    &, &.active, &.active:focus, &:focus{
      background: rgba(255,255,255,0.1);
    }

    b { top: -4px; }
  }

  /* Table */
  table.hover {
    tbody tr:hover td {
      background: ${color.blueDark};
      color: white;
      cursor: pointer;
    }
  }

  /* Pageination */
  ul.MuiPagination-ul {
    justify-content: center;
  }

  label {
    ${label}
  }

  input,
  input.form-control,
  input[type="text"],
  select,
  .MuiInputBase-input
  .Mui-focused {
    color: ${color.black} !important;
    font-size: 1rem !important;
  }

  /* Select */
  .MuiInputBase-root {
    display: block;
    width: 100%;
  }

  .rdt input.form-control, input.form-control {
    background-image: linear-gradient(${color.black},${color.black}), linear-gradient(${color.black},${color.black}) !important;

    &:focus {
      background-image: linear-gradient(${color.blueLight},${color.blueLight}), linear-gradient(${color.blueLight},${color.blueLight}) !important;
    }
  }

  .MuiInputBase-root::before {
    border-color: ${color.black} !important;
  }
  .MuiInputBase-root::after,.rdt input.form-control {
    border-color: ${color.blueLight} !important;
  }

  .text-area textarea {
    height: 150px !important;
  }

  h1,h2,h3,h4,h5,h6 {
    color: ${color.black};
  }

  button.touchpoint-edit {
    background: transparent;
    border: 0;
    color: ${color.blueDark};
    cursor: pointer;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

`;
