// A component for displaying a list of victims in a modal dialog using the useGetVictimSimpleList.
// When a victim is selected, the component should emit the selected victims ID.

import React from "react";
import { useGetVictimSimpleList } from "hooks/useGetVictimSimpleList";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Dialog, DialogContent } from "@material-ui/core";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "300px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function VictimSimpleListModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { show, close, token,  } = props;
  const [refresh, setRefresh] = React.useState(false);
  const { simpleListResponse, simpleListLoading, simpleListError } = useGetVictimSimpleList(token, refresh);
  const [selectedVictim, setSelectedVictim] = React.useState('');
  React.useEffect(() => {
    if (simpleListError) {
      enqueueSnackbar(simpleListError.message, { variant: "error" });
    }
  }, [simpleListError]);

  
    return (
      <Dialog
        open={show}
        onClose={close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent>
          {simpleListLoading && (<div>Loading...</div>)}
          {simpleListResponse && (
            <div>
            <Grid container spacing={2}>
              
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl  className={classes.FormControl}>
                  <InputLabel id="demo-simple-select-helper-label">Select Victim</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="victim"
                      label="Select Victim"
                      value={selectedVictim}
                      onChange={(event) => setSelectedVictim(event.target.value)}
                    >
                      {simpleListResponse.map((value) => (
                      <MenuItem key={value.id} value={value.id}>{`${value.first_name} ${value.last_name}`}</MenuItem>
                       ))}
                    </Select>
                  </FormControl>
                </Grid>
             
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => props.onVictimSelected(selectedVictim)}
                >
                  {t("select")}
                </Button>
              </Grid>
            </Grid>
            </div>
          )}
        </DialogContent>
      </Dialog>
    ); 
} 
