import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const style = (theme) => ({
  ...customSelectStyle,
  ...modalStyle(theme),

  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center",
  },
  avatar: {
    display: 'inline-flex',
    backgroundColor: '#1EC7E2',
  }
});

export default style;
