import React from "react";
import moment from "moment";
import styled from "styled-components";

// Material UI
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from '@material-ui/core/FormHelperText';


const DAYS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const MONTHS = [
  ["January", 1],
  ["February", 2],
  ["March", 3],
  ["April", 4],
  ["May", 5],
  ["June", 6],
  ["July", 7],
  ["August", 8],
  ["September", 9],
  ["October", 10],
  ["November", 11],
  ["December", 12],
];

const YEARS = [""];
const first = 1900;
const last = new Date().getFullYear();
for (var i = last; i >= first; i--) {
  YEARS.unshift(i);
}

const StyledInputDate = styled.div`
  .input-box {
    width: 33.33%;
  }
`;

export function InputDate({ label, onChange, value, name, helperText }) {
  
  const [day, setDay] = React.useState( "");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  const [currentValue, setCurrentValue] = React.useState();

  React.useEffect(() => {
    if (day && month && year) {
      onChange(name, `${year}-${month}-${day}`);
    }
  }, [day, month, year]);

  React.useEffect(() => {
    if(value != currentValue){
      setDay(value ? moment(value, "YYYY-MM-DD").format("DD") : "");
      setMonth(value ? moment(value, "YYYY-MM-DD").format("M") : "");
      setYear(value ? moment(value, "YYYY-MM-DD").format("YYYY") : "");
      setCurrentValue(value);
    }
  }, [value])

  return (
    <StyledInputDate>
      <label>{label}</label>
      <div>
        <FormControl className="input-box">
          <InputLabel htmlFor="relationship">Month</InputLabel>
          <Select
            inputProps={{
              value: month,
              onChange: (e) => setMonth(e?.target?.value),
            }}
          >
            <MenuItem disabled>Choose one</MenuItem>
            {MONTHS.map((item, index) => {
              return (
                <MenuItem key={index} value={item[1]}>
                  {item[0]}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>

        <FormControl className="input-box">
          <InputLabel htmlFor="relationship">Day</InputLabel>
          <Select
            inputProps={{
              value: day,
              onChange: (e) => setDay(e?.target?.value),
            }}
          >
            <MenuItem disabled>Choose one</MenuItem>
            {DAYS.map((item, index) => {
              if(item <= moment(month, "MM").daysInMonth() ){
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              } 
            })}
          </Select>
        </FormControl>

        <FormControl className="input-box">
          <InputLabel htmlFor="relationship">Year</InputLabel>
          <Select
            inputProps={{
              value: year,
              onChange: (e) => setYear(e?.target?.value),
            }}
            MenuProps={{
              style: {
                 maxHeight: 400,
                },
              }}
          >
            <MenuItem disabled>Choose one</MenuItem>
            {YEARS.map((item, index) => {
              if(month == 2 && day == "29"){
                if( moment(item, "YYYY").isLeapYear()) {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
                }
              } else {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </div>
    </StyledInputDate>
  );
}
