import React from "react";
import AdminLayout from "layouts/Admin.js";
import { useHistory } from "react-router-dom";
import { StateContext } from "state.js";
import { usePostTask } from "hooks";
import { useGetTasks } from "hooks";
import { useDeleteTask } from "hooks";
import { useTranslation } from 'react-i18next';
import { FormTask, DynamicDataTable } from "components";
import { formatDate } from "utilities";
import { ActiveUserDropdown } from "components";
import { makeStyles } from "@material-ui/core/styles";
// Bread crumbs
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

// Layout
import Grid from '@material-ui/core/Grid';
// Grid
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
// Box
import Box from "@material-ui/core/Box";
// material-ui icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Button from "components-material-ui/CustomButtons/Button.js";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";
// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function Page() {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  
  const defaultSort = {
    sortDirection: 'asc',
    field: 'due_date'
  };

  const [sort, setSort] = React.useState(defaultSort);
  const [error, setError] = React.useState(false);
  const [token] = React.useState(State?.user?.value?.token);
  const [user_id] = React.useState(State?.user?.value?.id);
  const [task, setTask] = React.useState();
  const [showCompleted, setShowCompleted] = React.useState();
  const [deleteTask, setDeleteTask] = React.useState(false);
  const [updateTask, setUpdateTask] = React.useState(false);
  const [updateTaskId, setUpdateTaskId] = React.useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [modal, setEditTaskModal] = React.useState(false);
  // Global editing
  const [isDeleteConfirmed, setIsDeleteConfirmed] = React.useState(false);

  const defaultFilter = ["by_not_completed=1"];
  const [search, setSearch] = React.useState(defaultFilter);
  const [version, setVersion] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState();
  const [filterUser, setFilterUser] = React.useState(user_id);

  const refresh = React.useCallback(() => {
    setIsLoading(false);
    setUpdateTask(null);
    setUpdateTaskId(null);
    setVersion(s => s + 1)
  }, [])


  const history = useHistory();
  const { tasks, tasksError, tasksPageCount, total, getIsLoading } = useGetTasks(
    token, 
    version, 
    page, 
    search, 
    perPage,
    sort,
    true
  );
  
  const { taskUpdateSuccess, updateTasksError, updateIsLoading } = usePostTask(token, updateTask, updateTaskId);
  const { taskDeleteSuccess, taskDeleteError, deleteIsLoading } = useDeleteTask(token, deleteTask, isDeleteConfirmed);

  const setTaskInfo = (response) => {
    setEditTaskModal(false);
    refresh();
    history.push("/tasks/view/"+response.id);
  }

  const classes = useStyles();

  // Handle errors
  React.useEffect(() => setError(true), [tasksError]);

  React.useEffect( () => { 
    if (getIsLoading || updateIsLoading || deleteIsLoading ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  
  }, [getIsLoading, updateIsLoading, deleteIsLoading]);

  React.useEffect(() => {
    if (tasks) {
      const data = tasks.map((k, v) => { 
        return {
          id: k.id,
          completed: k.completed,
          title: k.title, 
          description: k.description, 
          due_date: formatDate(k.due_date), 
          assigned_to: `${k.assigned_to.first_name} ${k.assigned_to.last_name}`, 
          actions: 
            <>
              <Button color="info" className={classes.actionButton} key={`edit-${k.id}`} component="a" href={"/tasks/view/"+k.id}>
                <Edit className={classes.icon} />
              </Button>
              <Button color="success" className={classes.actionButton} key={`delete-${k.id}`} onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteClick(k);
                            }} >
                <Delete className={classes.icon} />
              </Button>
            </>
        };
      });
      setTableData(data);   
    } else {
      setTableData([]);
    }
  }, [tasks])

  const columns = React.useMemo(() => [
    {
      name: 'Completed',
      selector: 'completed',
      sortable: false,
      filterable: true,
      cell: row => <>
          <IconButton  color="primary" className={classes.actionButton} key={3} onClick={(e) => {                                    
                          handleCompleteClick(row.id, row);
                        }} >
            { row.completed  && (<AssignmentTurnedIn className={classes.icon} />)}
            { !row.completed && (<Assignment className={classes.icon} />)}
          </IconButton >    
        </>
    },
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
      filterable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: false                
    },
    {
      name: 'Due Date',
      selector: 'due_date',
      sortable: true                
    },
    {
      name: 'Assigned To',
      selector: 'assigned_to',
      sortable: true                
    },
    {
      name: "",
      selector: "actions",
      sortable: false,
      filterable: false
    }
  ]);

  React.useEffect(() => {
    if (deleteTask && isDeleteConfirmed && taskDeleteSuccess) handleDeletedTask();
  }, [deleteTask, isDeleteConfirmed, taskDeleteSuccess]);

  const handleDeletedTask = () => {
    console.log("Handle DeleteTask");
    setDeleteConfirmModal(false);
    setIsDeleteConfirmed(false);
    refresh();
  }

  const handleDeleteClick = (task) => {
    setDeleteTask(task);
    setDeleteConfirmModal(true);
  }

  React.useEffect(() => {
    if (updateTask && taskUpdateSuccess) {
      handleCompletedTask();
    }
  }, [updateTask, taskUpdateSuccess]);

  const handleCompleteClick = (id, row) => {
    console.log(id, row);
    const task = {
      id: id,
      completed: !row.completed
    }
    setUpdateTask(task);
    setUpdateTaskId(task.id);
  };

  const handleCompletedTask = () => {
    console.log("handleComplete");
    refresh();
  }

  const handlePageChange = (page, totalRows) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const handleSort = (column, sortDirection) => {
    console.log(column, sortDirection);
    const newSort = {
       sortDirection: sortDirection,
       field:column.selector
    };
    setSort(newSort);
  };

  const handleFilter = (filter) => {
    console.log(filter);
    const filterString = `by_title=${filter}`
    const filters = [filterString]
    setSearch(filters);
  };

  // Filters
  React.useEffect(() => {
    // Set local var to serach params
    let currentSearch = search ? search : [];
    // Clear previous 
    if(Array.isArray(currentSearch)) {
      currentSearch=currentSearch.filter(s => s !== undefined && s !== 'by_completed=1' && s !== 'by_not_completed=1' );   
    } 
    // only set if not all
    if(showCompleted!=="all") {
      currentSearch.push(showCompleted);
    }
    setSearch(currentSearch); 
                                 
  }, [showCompleted])

  React.useEffect(() => {
    // Set local var to serach params
    let currentSearch = search ? search : [];
    // Clear previous 
    if(Array.isArray(currentSearch)) {
      currentSearch=currentSearch.filter(s => s !== undefined && !s.startsWith('by_assigned_id') );   
    } 
    // only set if not all
    if(filterUser !== -1) currentSearch.push(`by_assigned_id=${filterUser}`);
    setSearch(currentSearch); 
                                 
  }, [filterUser])

  return (
    <div>
      {isLoading && (
            <Box textAlign="center" pb={5}>
              <p>Please wait</p>
              <CircularProgress />
            </Box>
          )}
      <GridContainer>
        <GridItem xs={10}>
          <h1>Tasks</h1>
        </GridItem>
        <GridItem xs={2} px={10}>
          <Button
            variant="contained"
            color="primary"
            size="lg"
            className={classes.button}
            startIcon={<Add />}
            onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setEditTaskModal(true);
                }
            }>
            {t('task.new')}
          </Button>                            
        </GridItem>
      </GridContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/" >
          Dashboard
        </Link>
        <Typography color="textPrimary">Tasks</Typography>
      </Breadcrumbs>

      
      <DynamicDataTable
            title={"Tasks"}
            data={tableData}
            columns = {columns}
            rows={total}
            defaultSortField={defaultSort.field}
            defaultSortAsc={defaultSort.sortDirection === 'asc'}
            perRowsChangeCallback={handlePerRowsChange}
            pageChangeCallback={handlePageChange}
            sortCallback={handleSort}
            filterCallback={handleFilter}
            initialPerPage={perPage}
            isLoading= {getIsLoading || deleteIsLoading}
            filterPlaceholder="Filter Title"
            filterComponents={[
              <Box p={10} flexGrow={1} key={2} >
                <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="show-completed-label">Show</InputLabel>
                <Select
                  labelId="show-completed-label"
                  id="show-completed"
                  defaultValue={"by_not_completed=1"}
                  onChange={ (e) => {setShowCompleted(e.target.value)}}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"by_completed=1"}>Completed</MenuItem>
                  <MenuItem value={"by_not_completed=1"}>Due</MenuItem>
                </Select>
              </FormControl>
            </Box>
            ,
            <Box p={10} flexGrow={1} key={3}>
            <FormControl className={classes.formControl} fullWidth>
            <ActiveUserDropdown label="Assign To" 
              onChange={(val) => {setFilterUser(val)}} 
              value={user_id}
              disableChoose={false}
            />
            </FormControl>
            </Box>
            ]}
          />  

       {/** Task Edit Modal */}
       <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modal}
          transition={Transition}
          keepMounted
          onClose={() => setEditTaskModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setEditTaskModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>New Task</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            {modal && (
            <FormTask id={task?.id} title={task?.title} description={task?.description} assigned_to={task?.assigned_to} due_date={task?.due_date} setTask={setTaskInfo} />
            )}
          </DialogContent>
        </Dialog>
        {/** Delete Confirm Dialog */}
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={deleteConfirmModal}
          transition={Transition}
          keepMounted
          onClose={() => setDeleteConfirmModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={ () => setDeleteConfirmModal(false) }
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Confirm Delete</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <h5>Are you sure you want to do this?</h5>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => setDeleteConfirmModal(false)}>Never Mind</Button>
            <Button onClick={() => setIsDeleteConfirmed(true) } color="success">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

export default function PageTasksList() {
    return <AdminLayout component={<Page />} />;
  }
