import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { max, min } from 'lodash';

function VictimDiff({ source, target, handleChange }) {
  const [mergedVictim, setMergedVictim] = useState({});
  const [initialized, setInitialized] = useState(false);

  const victimKeys = Object.keys(source).filter(
    (attr) => !["survivors", "incident", "socials", "case", "related_victims", "related_survivors", "created_at", "updated_at","deleted_date", "case_id",  "is_deleted"].includes(attr)
  );

  const initializeMergedVictim = () => {
    const initialValues = {};
    victimKeys.forEach((key) => {
      initialValues[key] = source[key];
    });
    console.log("initialValues", initialValues);
    setMergedVictim(initialValues);
    setInitialized(true);
  };

  React.useEffect(() => {
    console.log("mergedVictim", mergedVictim);
    if(Object.keys(mergedVictim).length > 0) return;

    console.log("victimKeys", victimKeys);
    if (victimKeys && victimKeys.length > 0) {
      initializeMergedVictim(); 
    }
  }, [victimKeys,mergedVictim]);

  React.useEffect(() => {
    if(!initialized) return;
    
  }, [mergedVictim, initialized]);


  const attributes2 = Object.keys(target).filter(
    (attr) => !["survivors", "incident", "socials"].includes(attr)
  );

  const sourceValues = Object.values(source);

  const handleCheckboxChange = (event, obj, attr) => {
    const { checked } = event.target;
    if (checked) {
      setMergedVictim((prevValues) => ({ ...prevValues, [attr]: obj[attr] }));
      if(handleChange) handleChange(attr, obj[attr]);
    } else {
      setMergedVictim((prevValues) => {
        const newValues = { ...prevValues };
        delete newValues[attr];
        return newValues;
      });
      if(handleChange) handleChange(attr, null);
    }
  };

  const styles = {
    container: {
      padding: "2em",
      float: "left",
      width: "100%",
      td: {
        width: "25% !important", 
        maxWidth: "25%"
      }
    },    
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <table className="rwd-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Victim({source.id})</th>
            <th>Victim({target.id})</th>
            <th>Merged</th>
          </tr>
        </thead>
        <tbody>
          {victimKeys.map((value, index) => (
          <tr key={index}>
            <td>
              <span>{value}</span>
            </td>
            <td>
            {value != "id" && (<input
                type="checkbox"
                checked={mergedVictim[value] === source[value]}
                onChange={(event) => handleCheckboxChange(event, source, value)}
              />)}
              <span>
                {source[value]}
              </span>
            </td>
            <td>
            {value != "id" && (<input
                type="checkbox"
                checked={mergedVictim[value] === target[value]}
                onChange={(event) => handleCheckboxChange(event, target, value)}
              />)}
              <span>
                {target[value]}
              </span>
            </td>
            <td>
              <span>{mergedVictim[value]}</span>
            </td>
          </tr>
         ))}
          
        </tbody>
      </table>     
    </div>
  );
}

export default VictimDiff;
